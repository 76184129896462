<template>
  <div class="order-detailed">
    <div class="order-detailed__container _container">
      <h1 class="order-detailed__title">
        {{ $t("cart.you_order") }} - {{ orderObj.order_type_name }}
      </h1>
      <div v-if="orderObj && orderObj.items.length <= 0">
        <br />
        <h2 class="order-detailed__title">
          <br />{{ $t("cart.cart_empty") }}.<br /><br />{{
            $t("cart.cart_empty2")
          }}<br /><a href="/products/" class="btn btn-beige to-catalog"
            >{{ $t("cart.to_catalog") }}.</a
          >
        </h2>
      </div>
      <div class="block-rows">
        <div class="block-orders">
          <div
            class="block-order"
            v-for="item in orderObj.items"
            :key="item.id"
          >
            <div class="block-order__flex">
              <div class="block-order__img">
                <img
                  :src="
                    item.product.image
                      ? item.product.image
                      : require(`@/assets/img/${nophoto}`)
                  "
                  :alt="item.product.real_name"
                />
              </div>
              <div class="block-order__content">
                <div class="block-order__left">
                  <div class="block-order__title">
                    <p>{{ item.product.real_name }}</p>
                  </div>
                  <div class="block-order__weight">
                    <p>{{ Math.round(item.product.weight) }} гр</p>
                  </div>
                </div>
                <div class="block-order__middle">
                  <div class="counter">
                    <button class="counter__minus">
                      <img
                        src="@/assets/img/svg/minus.svg"
                        alt="minus"
                        @click="
                          changeOrderItem(orderObj.id, item.id, 'decrease')
                        "
                      />
                    </button>
                    <span class="counter__counts">x{{ item.quantity }}</span>
                    <button class="counter__plus">
                      <img
                        src="@/assets/img/svg/plus.svg"
                        alt="plus"
                        @click="
                          changeOrderItem(orderObj.id, item.id, 'increase')
                        "
                      />
                    </button>
                  </div>
                  <div class="block-order__price">
                    {{ item.product.price }} ₽
                  </div>
                </div>
                <div class="block-order__right">
                  <button
                    class="btn btn-trash"
                    @click="changeOrderItem(orderObj.id, item.id, 'delete')"
                  >
                    <img src="@/assets/img/svg/trash.svg" alt="trash" />
                    {{ $t("buttons.remove") }}
                  </button>
                  <button class="btn btn-to-change">
                    <img src="@/assets/img/svg/to-change.svg" alt="to-change" />
                    Заменить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="notification-phone" v-if="orderObj.phone">
          {{ $t("cart.you_number") }}: +7{{ orderObj.phone }}
        </div>
      </div>
    </div>
  </div>
  <t-total-sum :orderObj="orderObj" />
  <t-footer-order />
  <div class="success" v-if="isPopupSuccess">
    <p>{{ success }}</p>
    <button class="btn btn-green" @click="isPopupSuccess = false">ОК</button>
  </div>
  <div class="keyboard-block" v-if="isPopupKeyboad" @click="cancelClose()">
    <div class="keyboard" @click.stop>
      <div class="keyboard__block">
        <div class="keyboard__container _container">
          <div class="keyboard__body">
            <input
              type="text"
              v-model="message"
              :placeholder="$t('cart.input_promo')"
              class="keyboard__title"
            />
            <div class="keyboard__keys" @click="typeLetter">
              <button
                class="keyboard__key key"
                v-for="(number, index) in 9"
                :key="index"
              >
                {{ number }}
              </button>
              <button class="keyboard__key key">0</button>
              <button class="btn btn-green key">
                <img
                  src="@/assets/img/svg/clear.svg"
                  alt="clear"
                  class="clear"
                />
              </button>
              <button class="btn btn-green btn-long key">
                {{ $t("cart.btn_clear") }}
              </button>
              <button
                class="keyboard__key key"
                v-for="letter in letters"
                :key="letter.id"
              >
                {{ letter.char }}
              </button>
              <button class="btn btn-green key">
                <img
                  src="@/assets/img/svg/space.svg"
                  alt="space"
                  class="space"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="keyboard__buttons">
        <button class="btn btn-red" @click="cancelClose()">
          {{ $t("cart.cancel") }}
        </button>
        <button class="btnApply" @click="addDiscount()">
          {{ $t("cart.btn_apply") }}
        </button>
      </div>
    </div>
  </div>
  <div class="keyboard-block" v-if="isPopupPhone" @click="closePopup()">
    <div class="keyboard" @click.stop>
      <div class="keyboard__block">
        <div class="keyboard__container _container">
          <div class="keyboard__body phone-body">
            <div class="keyboard__phone">
              +7<input
                type="text"
                placeholder="___ ___ __ __"
                class="keyboard__title"
                :class="{
                  notvalid: phoneValidation.phone,
                  valid: phoneValidation.phone === false,
                }"
                v-model="phone"
                @input="inputPhone"
              />
            </div>
            <div class="keyboard__keys phone-rows" @click="inputPhone">
              <div class="phone-row">
                <button class="keyboard__key key">1</button>
                <button class="keyboard__key key">2</button>
                <button class="keyboard__key key">3</button>
              </div>
              <div class="phone-row">
                <button class="keyboard__key key">4</button>
                <button class="keyboard__key key">5</button>
                <button class="keyboard__key key">6</button>
              </div>
              <div class="phone-row">
                <button class="keyboard__key key">7</button>
                <button class="keyboard__key key">8</button>
                <button class="keyboard__key key">9</button>
              </div>
              <div class="phone-row">
                <button class="btn btn-green btn-clear key">X</button>
                <button class="keyboard__key key">0</button>
                <button class="btn btn-green key">
                  <img
                    src="@/assets/img/svg/clear.svg"
                    alt="clear"
                    class="clear"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="keyboard__buttons">
        <button class="btn btn-red" @click="closePopup()">
          {{ $t("cart.cancel") }}
        </button>
        <button class="btnAddPhone" @click="validPhone()">
          {{ $t("cart.btn_add") }}
        </button>
      </div>
    </div>
  </div>
  <div class="auth" v-if="isAuth">
    <div class="auth__container">
      <div class="auth__body">
        <div class="auth-sign" v-if="authSign">
          <h2 class="auth-sign__title">
            Авторизуйтесь и возвращайте 5% бонусами
          </h2>
          <div class="auth-sign__img">
            <img :src="require(`@/assets/img/` + coins_img)" :alt="coins_img" />
          </div>
        </div>
        <div class="phone-input" v-if="phoneInput">
          <div
            class="phone-input__phone"
            :class="{
              notvalid: phoneValidation.phone,
              valid: phoneValidation.phone === false,
            }"
          >
            <span>+7</span>
            <input
              type="text"
              v-mask="'(###) ### ##-##'"
              name="phone"
              placeholder="___ ___ __ __"
              id="phone"
              v-model.trim="phone"
              @input="inputPhone"
            />
          </div>
          <div class="phone-input__keys phone-rows" @click="inputPhone">
            <div class="phone-row">
              <button class="phone-input__key key">1</button>
              <button class="phone-input__key key">2</button>
              <button class="phone-input__key key">3</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">4</button>
              <button class="phone-input__key key">5</button>
              <button class="phone-input__key key">6</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">7</button>
              <button class="phone-input__key key">8</button>
              <button class="phone-input__key key">9</button>
            </div>
            <div class="phone-row">
              <button class="btn btn-green btn-clear key">X</button>
              <button class="phone-input__key key">0</button>
              <button class="btn btn-green key">
                <img
                  src="@/assets/img/svg/clear.svg"
                  alt="clear"
                  class="clear"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="auth-success" v-if="authSuccess">
          <h2 class="auth-success__title">{{ success }}</h2>
          <div class="auth-success__phone">
            <p class="auth-success__our-tel">Ваш телефон</p>
            <div class="auth-success__input-tel">+7 {{ phone }}</div>
          </div>
        </div>
      </div>
      <div class="auth__bottom">
        <div class="auth__buttons" v-if="authSign">
          <button class="btn btn-red" @click="closeAuth()">Пропустить</button>
          <button class="btn btn-green" @click="showPhoneInput()">Войти</button>
        </div>
        <div class="auth__buttons" v-if="phoneInput">
          <button class="btn btn-red" @click="closeAuth()">Закрыть</button>
          <button class="btn btn-green btnAddPhone" @click="validPhone2()">
            Войти
          </button>
        </div>
        <div class="auth__buttons" v-if="authSuccess">
          <button class="btn btn-green" @click="closeAuth()">Продолжить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-order-detailed",
  data() {
    return {
      isAuth: true,
      authSign: true,
      phoneInput: false,
      authSuccess: false,
      orderId: localStorage.getItem("orderId"),
      orderObj: { items: [] },
      isPopupKeyboad: false,
      isPopupPhone: false,
      deduct_points: `Накопить | Списать баллы`,
      discount_error: "",
      message: ``,
      success: `Неверный промокод! Попробуйте ещё раз!`,
      isPopupSuccess: false,
      letters: [
        { id: 1, char: `й` },
        { id: 2, char: `ц` },
        { id: 3, char: `у` },
        { id: 4, char: `к` },
        { id: 5, char: `е` },
        { id: 6, char: `н` },
        { id: 7, char: `г` },
        { id: 8, char: `ш` },
        { id: 9, char: `щ` },
        { id: 10, char: `з` },
        { id: 11, char: `х` },
        { id: 12, char: `ъ` },
        { id: 13, char: `ё` },
        { id: 14, char: `ф` },
        { id: 15, char: `ы` },
        { id: 16, char: `в` },
        { id: 17, char: `а` },
        { id: 18, char: `п` },
        { id: 19, char: `р` },
        { id: 20, char: `о` },
        { id: 21, char: `л` },
        { id: 22, char: `д` },
        { id: 23, char: `ж` },
        { id: 24, char: `э` },
        { id: 25, char: `я` },
        { id: 26, char: `ч` },
        { id: 27, char: `с` },
        { id: 28, char: `м` },
        { id: 29, char: `и` },
        { id: 31, char: `т` },
        { id: 32, char: `ь` },
        { id: 33, char: `б` },
        { id: 34, char: `ю` },
        { id: 35, char: `_` },
      ],
      phoneValidation: {
        phone: null,
      },
      phone: ``,
      coins_img: `coins.png`,
      nophoto: "nophoto.jpg",
    };
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeAuth() {
      this.isAuth = false;
      this.phone = "";
    },
    showPhoneInput() {
      this.authSign = false;
      this.phoneInput = true;
    },
    applyPromo() {
      this.isPopupKeyboad = true;
    },
    cancelClose() {
      this.isPopupKeyboad = false;
      this.message = "";
    },
    applyPhone() {
      this.isPopupPhone = true;
    },
    closePopup() {
      this.isPopupPhone = false;
      this.phone = "";
    },
    showPrice: function (product_id) {
      let output = 0;
      this.orderObj.items.forEach(function (item) {
        if (item.product.id == product_id) {
          console.log(item.price, item.quantity, item.price * item.quantity);
          output = item.product.price * item.quantity;
        }
      });
      return output;
    },
    getOrderItem: function (product_id) {
      let output = 0;
      this.orderObj.items.forEach(function (item) {
        if (item.product.id == product_id) {
          output = item.id;
        }
      });
      return output;
    },
    addToOrder(order_id, product_id) {
      let self = this;
      axios
        .post(api + `order-add/`, {
          order_id: order_id,
          product_id: product_id,
        })
        .then(function (response) {
          self.orderObj = response.data;
        });
    },
    changeOrderItem(order_id, order_item_id, type) {
      let self = this;
      axios
        .post(api + `order-change/`, {
          order_id: order_id,
          order_item_id: order_item_id,
          type: type,
        })
        .then(function (response) {
          self.orderObj = response.data;
        });
    },
    addDiscount() {
      let self = this;
      self.isPopupSuccess = true;
      axios
        .post(api + `discount-add/`, {
          order_id: self.orderObj.id,
          discount_slug: this.message,
        })
        .then(function (response) {
          self.orderObj = response.data;
          self.isPopupKeyboad = false;
          self.success = `Промокод применён!`;
        })
        .catch(function (response) {
          self.message = "";
          self.isPopupKeyboad = false;
          self.discount_error = response.data.error;
          self.success = `Неверный промокод! Попробуйте ещё раз!`;
        });
    },
    typeLetter() {
      if (
        event.target.className !== "keyboard__keys" &&
        event.target.className !== "btnApply"
      ) {
        let key = event.target.innerHTML || event.target.className;
        if (key === "clear")
          this.message = this.message.substr(0, this.message.length - 1);
        else if (key === "Очистить") this.message = "";
        else if (key === "space") this.message += " ";
        else this.message += key.toUpperCase();
      }
    },
    inputPhone() {
      if (
        event.target.className !== "keyboard__keys" &&
        event.target.className !== "phone-row" &&
        event.target.className !== "btnAddPhone"
      ) {
        let number = event.target.innerHTML || event.target.className;
        if (number === "clear")
          this.phone = this.phone.substr(0, this.phone.length - 1);
        else if (number === "X") this.phone = "";
        else {
          this.phone.length > 9
            ? (this.phone = this.phone.substr(0, this.phone.length))
            : (this.phone += number);
        }
      }
      this.phoneValidation.phone = !/^[\d]{10}$/.test(this.phone)
        ? true
        : false;
    },
    addPhone() {
      axios
        .post(api + `phone-add/`, {
          order_id: this.orderObj.id,
          phone: this.phone,
        })
        .then(function (response) {
          this.orderObj = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validPhone() {
      this.isPopupSuccess = true;
      if (!this.phoneValidation.phone && this.phone.length > 0) {
        this.success = `Телефон добавлен!\nВаш телефон: +7${this.phone}`;
        this.addPhone();
      } else {
        this.success = `Проверьте корректность ввода телефона!`;
      }
      this.closePopup();
    },
    validPhone2() {
      if (!this.phoneValidation.phone && this.phone.length > 0) {
        this.success = `Вы успешно авторизованы!`;
        this.addPhone();
        this.authSuccess = true;
        this.phoneInput = false;
      } else {
        this.authSuccess = false;
        this.phoneInput = true;
      }
    },
  },
  created() {
    if (!this.orderId) {
      this.$router.push("/");
    }
    let self = this;
    axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
      self.orderObj = response.data;
    });
    axios
      .get(api + `recommended-in-cart/?order_id=` + this.orderId)
      .then(function (response) {
        self.recommendedProducts = response.data;
      });
    this.discount_error = "";
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/keyboards";
@import "~@/assets/scss/auth";
.block-rows {
  display: flex;
  flex-direction: column;
  height: 58vh;
}
.block-order__title p {
  width: 100%;
}
.block-apply {
  display: flex;
  padding: 0 38px;
  margin: 0 -1rem;
  .apply-promo {
    width: 100%;
    height: 70px;
    margin: 2rem 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $ms3) {
      height: 50px;
    }
    @media (max-width: $ms4) {
      margin-top: 1rem;
    }
  }
}
.to-catalog {
  width: fit-content;
  margin: 1rem auto;
  padding: 10px 2rem;
}
.block-orders {
  padding: 0 25px 15px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #1e6350;
  border-radius: 20px;
  box-shadow: 20px 0px 0px 0px #1e6350;

  &::-webkit-scrollbar-track {
    background: #28735f;
    border-radius: 100px;
    margin: 15px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #76a597;
    border-radius: 100px;
    height: 183.6px;
  }

  @media (max-width: $ms3) {
    padding: 0 15px;
  }
  @media (max-width: $ms4) {
    padding: 0 10px;
  }
}

.order-detailed {
  background-color: $green;
  box-shadow: 0 0 0px 1px #28735f;
  position: relative;
  overflow-x: hidden;
  height: 75vh;
  &::-webkit-scrollbar-track {
    background: rgba(40, 115, 95, 0.2);
    border-radius: 10px;
    margin-top: 28px;
  }

  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background: #28735f;
    border-radius: 10px;
  }
  @media (max-width: $ms3) {
    padding-top: 50px;
  }
  &__promocode {
    @media (max-width: $ms3) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    text-align: center;
    color: #fff;
    @include adaptive-font(50, 18, 0);
    @include adaptive-value("line-height", 51, 20, 0);
    padding-top: 200px;
    padding-bottom: 5rem;
  }

  &__promocode {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: $ms3) {
      margin: 20px auto 0;
    }

    .btn {
      flex: 1 1 50%;
      height: 70px;
      display: flex;
      justify-content: center;
      margin: 45px 15px 0;
      @media (max-width: $ms3) {
        width: 100%;
        height: 50px;
        margin: 20px 15px 0;
      }
    }
  }
}
</style>
