<template>
  <t-top />
  <div class="payment">
    <div class="payment__container _container">
      <div class="payment__body">
        <h1 class="payment__title">{{ $t("payment.title") }}</h1>
        <div v-if="onProccess">
          <div class="payment__loads">
            <img src="@/assets/img/load.gif" alt="loads" />
          </div>
          <h2 class="payment__subtitle">
            <br />
            {{ $t("payment.subtitle") }}<br />
            {{ $t("payment.subtitle1") }}<br />
            {{ $t("payment.subtitle2") }}<br />{{ $t("payment.subtitle3") }}
          </h2>
        </div>
        <div v-if="!onProccess && !paymentError">
          <h2 class="payment__subtitle">
            <br />
            <div v-if="!orderObj.order_number">
              {{ $t("payment.subtitle_err") }}<br />
              {{ $t("payment.subtitle_err1") }}<br />
              {{ $t("payment.subtitle_err2") }}<br />
              {{ $t("payment.subtitle_err3") }}<br />
            </div>
            <span class="order-number" v-if="orderObj.order_number"
              >{{ $t("payment.number_order") }} <br /><font
                v-if="!orderObj.name"
                >{{ $t("payment.name_kiosk") }}-{{
                  orderObj.order_number
                }}</font
              ><font v-if="orderObj.name">{{ orderObj.name }}</font></span
            >
          </h2>
          <div class="order-notifications" v-if="orderObj.order_number">
            <p>
              {{ $t("payment.notifications") }}<br />
              {{ $t("payment.notifications2") }}
            </p>
          </div>
        </div>
        <!--
        <div class="give-check" v-if="orderObj.order_number">
          <p>{{ $t("payment.give_check") }}</p>
          <img src="@/assets/img/svg/down.svg" alt="arrow" width="100" />
        </div>
        -->
        <div v-if="!onProccess && paymentError">
          <h2 class="payment__subtitle">
            <br />
            {{ $t("payment.pay_err") }}<br />
            {{ $t("payment.pay_err2") }}<br />
            {{ $t("payment.pay_err3") }}<br />
            <a
              @click="this.$router.go(this.$router.currentRoute)"
              class="btn btn-beige to-catalog"
              >{{ $t("payment.repeat") }}</a
            >
            <a
              @click="this.$router.push('/')"
              class="btn btn-beige to-catalog"
              >{{ $t("payment.cancel") }}</a
            >
          </h2>
        </div>
      </div>
    </div>
  </div>
  <t-ornament />
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-payment",
  data() {
    return {
      onProccess: true,
      paymentError: true,
      orderObj: {},
      orderId: localStorage.getItem("orderId"),
    };
  },
  mounted() {
    if (!this.orderId) {
      this.$router.push("/");
    }
    let self = this;
    axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
      self.orderObj = response.data;
      axios
        .get(
          "http://127.0.0.1:5000/payment/create/?amount=" +
            self.orderObj.full_price * 100
        )
        .then((response) => {
          self.onProccess = false;
          if (response.data.success) {
            self.paymentError = false;
            axios
              .post(api + `order-confirm/`, {
                order_id: self.orderId,
                comment: "Заказ сделан через планшет",
              })
              .then((response) => {
                self.onProccess = false;
                self.orderObj = response.data;

                axios.get(
                  "http://127.0.0.1:5000/receipt/print/?text=" +
                    self.orderObj.receipt_text
                );

                setTimeout(function () {
                  location.href = "/";
                }, 12000);
              });
          }
        })
        .catch(() => {
          self.paymentError = true;
          self.onProccess = false;
        });
    });
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.order-notifications {
  margin-top: 2rem;
}
.give-check {
  position: absolute;
  bottom: 0;
  right: 1rem;
  @include adaptive-font(55, 14, 0);
  @include adaptive-value("line-height", 50, 20, 0);
}
.order-number {
  padding: 5px 15px;
  margin: 10px auto 0;
  display: block;
  background: #d66b51;
  border-radius: 10px;
  width: fit-content;
}
.order-number font {
  font-weight: bold;
}
.order-info {
  @include adaptive-font(55, 14, 0);
  @include adaptive-value("line-height", 50, 20, 0);
  width: 50%;
  display: block;
  margin: 1rem auto 0;
}
.payment {
  background-color: $green;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  @include adaptive-value("padding-bottom", 200, 50, 0);
  height: 79vh;
  position: relative;
  &__body {
    color: #fff;
    text-align: center;
    @include adaptive-font(70, 20, 0);
    @include adaptive-value("line-height", 85, 20, 0);
  }

  &__title {
    font-weight: 700;
    text-transform: uppercase;
  }

  &__loads {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    margin: 109px auto 0;
    @media (max-width: $ms3) {
      width: 50px;
      height: 50px;
      margin: 50px auto 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
