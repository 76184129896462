<template>
  <header class="header">
    <div class="header__container _container">
      <t-logo />
    </div>
  </header>
</template>

<script>
export default {
  name: "t-top",
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.header {
  @include adaptive-value("padding-bottom", 75, 20, 0);
}
</style>
